import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/usr/share/nginx/distv2-light/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/header/component/auth-button-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/header/component/header-profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/header/component/language-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/header/component/menu-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/header/component/sidenav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/LoadingIndicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/components/NavigationEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuProvider"] */ "/usr/share/nginx/distv2-light/components/useMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next-intl@3.24.0_next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next-intl@3.24.0_next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next-intl@3.24.0_next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"],\"subsets\":[\"latin\"]}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/styles/icons.css");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/effect-cards.css");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/effect-creative.css");
