"use client";
import dynamic from "next/dynamic";
// import { PaymentPortalModal } from "@/components/PaymentPortal";
import { TransactionModal } from "@/components/TransactionModal";
import TransactionButtons from "@/components/TransactionsButton";
import { TransformBalance } from "@/lib/helper";
import { useDisclosure } from "@nextui-org/use-disclosure";
import { useState } from "react";

const PaymentPortalModal = dynamic(() => import("@/components/PaymentPortal").then((mod) => mod.PaymentPortalModal), {
  ssr: false,
});

export default function HeaderProfile({
  username,
  balance,
  currency,
}: {
  username?: string;
  balance?: number;
  currency?: string;
}) {
  const disclosure = useDisclosure();
  const paymentPortalDisclosure = useDisclosure();
  const [transactionType, setTransactionType] = useState<"deposit" | "withdrawal">("deposit");
  const [amount, setAmount] = useState<number>();
  return (
    <>
      <TransactionModal
        disclosure={disclosure}
        currency={currency!}
        transactionType={transactionType}
        currentBalance={String(balance!)}
        onTransactionExecute={(e) => {
          console.log(e);
          setAmount(e?.amount as number);
          disclosure.onClose();
          paymentPortalDisclosure.onOpen();
        }}
      />
      <PaymentPortalModal amount={amount} method={transactionType} disclosure={paymentPortalDisclosure} />
      <div className="grid grid-cols-2 gap-2">
        <div>
          <div>{username}</div>
          <div className="rounded-md bg-black py-1.5 text-tiny text-white">{TransformBalance(balance!, currency!)}</div>
        </div>
        <TransactionButtons
          className="!static left-0 top-0"
          depositOnClick={() => {
            setTransactionType("deposit");
            disclosure.onOpen();
          }}
          withdrawOnClick={() => {
            setTransactionType("withdrawal");
            disclosure.onOpen();
          }}
        />
      </div>
    </>
  );
}
