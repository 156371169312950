export const TransformBalance = (balance: number, currency: string) => {
  return (
    balance
      ?.toLocaleString()
      .split(".")
      .map((v, i) => {
        return i === 0 ? v : v.padEnd(2, "0");
      })
      .join(".") +
    " " +
    currency
  );
};
