"use client";
import { Button } from "@nextui-org/react";

import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import { useMenu } from "@/components/useMenu";

export default function MenuButton() {
  const { toggleMenu } = useMenu();
  return (
    <>
      <Button
        // whileTap={{ scale: 0.95 }}
        onClick={() => toggleMenu()}
        className="aspect-square h-auto w-8 min-w-8 rounded-md bg-transparent p-0"
      >
        <MenuSharpIcon sx={{ width: "100%" }} />
      </Button>
    </>
  );
}
