export default function BankCard(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0113 14.9044C15.154 15.6092 15.4258 16.3608 15.8259 17.1032L17.9363 15.8848L17.0303 14.3156C16.4009 14.64 15.7163 14.8404 15.0113 14.9044Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5953 11.8303C14.9093 12.4171 14.8313 13.5293 14.9273 14.3758C15.5677 14.3223 16.1902 14.1446 16.7631 13.8531L15.5953 11.8303Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5947 12.2583C17.9099 11.7516 16.9074 11.2629 16.0563 11.5637L17.2241 13.5866C17.7632 13.2361 18.2283 12.7859 18.5947 12.2583Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.475 11.0765C17.9781 11.2304 18.4513 11.4941 18.8755 11.8034C19.0008 11.5737 19.1076 11.3352 19.1949 11.0903C18.628 10.9861 18.0435 10.9809 17.475 11.0765Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3933 14.3915C14.3374 13.8698 14.3457 13.3281 14.4637 12.8157C14.0932 13.2651 13.8083 13.7723 13.6164 14.3113C13.8723 14.3582 14.1319 14.3851 14.3933 14.3915Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3644 17.3697C14.92 16.5482 14.6227 15.7112 14.4732 14.9265C14.1336 14.9239 13.7955 14.8896 13.4638 14.8252C13.197 15.9395 13.3121 17.1467 13.8656 18.2348L15.3641 17.3695L15.3644 17.3697Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0108 12.5955C18.6033 13.174 18.0873 13.6667 17.4916 14.0493L18.3976 15.6184L20.5074 14.4003C20.0647 13.6824 19.5496 13.0713 19.0108 12.5955Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.481 19C16.1669 18.6343 15.883 18.2419 15.6307 17.8313L14.1324 18.6963C14.2002 18.8 14.2713 18.9016 14.3462 19H16.481Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8742 19C18.2288 18.5786 18.6454 18.2132 19.1083 17.9155L18.2024 16.3463L16.092 17.5647C16.4102 18.0802 16.7802 18.5653 17.2027 19H17.8742Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7346 19L19.3756 18.3782C19.0971 18.5591 18.8372 18.7672 18.6006 19H19.7346Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6076 17.7975C20.342 17.88 20.0847 17.9854 19.8367 18.1114L20.2775 18.8749C20.4823 18.7375 20.6076 18.5083 20.6076 18.2602V17.7975Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6076 14.9576L18.6639 16.0798L19.5701 17.6494C19.9006 17.4789 20.2497 17.341 20.6076 17.2404V14.9576Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6076 11.5766C20.322 11.4275 20.0233 11.3064 19.7161 11.2151C19.606 11.535 19.4667 11.8445 19.2993 12.1401C19.7606 12.5379 20.2055 13.0256 20.6076 13.5875V11.5766Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6935 19C12.8398 17.6896 12.5726 16.0802 12.9806 14.5572C13.8754 11.2178 17.5175 9.55944 20.6076 10.9816V7.04438C20.6076 6.638 20.2742 6.30486 19.8681 6.30486H1.73951C1.33314 6.30486 1 6.63824 1 7.04438V18.2602C1 18.6664 1.33338 18.9998 1.73951 18.9998L13.6935 19ZM4.00395 7.76748H7.55551C8.40369 7.76748 9.09684 8.46063 9.09684 9.30881V10.9688C9.09684 11.817 8.40369 12.5101 7.55551 12.5101H4.00395C3.15576 12.5101 2.46262 11.817 2.46262 10.9688V9.30881C2.46262 8.46063 3.15576 7.76748 4.00395 7.76748ZM3.00762 9.1678C3.07777 8.67487 3.50483 8.3025 4.00395 8.3025H5.21142C5.01691 8.56882 4.85165 8.85797 4.73775 9.1678H3.00739H3.00762ZM4.7601 11.11C4.87733 11.418 5.04022 11.7064 5.22997 11.9753H4.00395C3.50483 11.9753 3.07753 11.603 3.00762 11.11H4.7601ZM8.55183 11.11C8.48169 11.603 8.05462 11.9753 7.55551 11.9753H6.32949C6.51924 11.7064 6.68213 11.418 6.79935 11.11H8.55183ZM6.82171 9.1678C6.70757 8.85797 6.54231 8.56882 6.34804 8.3025H7.55551C8.05462 8.3025 8.48192 8.67487 8.55183 9.1678H6.82171ZM4.76534 10.575H2.99764V9.70282H4.76272C4.85926 9.967 4.84571 10.3085 4.76534 10.575ZM6.32568 10.8525C6.20275 11.2023 6.00491 11.5288 5.77997 11.822C5.55478 11.5285 5.35695 11.2025 5.23425 10.8525C5.39975 10.4143 5.41877 9.8386 5.21832 9.40844C5.33935 9.052 5.54194 8.72933 5.77997 8.4397C6.01775 8.72933 6.22058 9.052 6.34162 9.40844C6.1414 9.83836 6.16042 10.4145 6.32568 10.8525ZM6.79698 9.70306H8.56206V10.5753H6.79436C6.71399 10.3087 6.70044 9.96724 6.79698 9.70306Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.13876 5.76984H19.8678C20.5693 5.76984 21.1426 6.34291 21.1426 7.04461V12.2507L21.8621 5.8476C21.9075 5.44408 21.6139 5.07456 21.2101 5.02914L3.19476 3.00463C2.79124 2.95922 2.42148 3.25288 2.37607 3.65688L2.13876 5.76984Z"
      />
    </svg>
  );
}
