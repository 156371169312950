"use client";
import { useRadio, VisuallyHidden, cn, RadioProps } from "@nextui-org/react";

export default function BankAccountRadio(props: RadioProps) {
  const {
    Component,
    children,
    isSelected,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props);

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        "group inline-flex flex-row-reverse items-center justify-between tap-highlight-transparent hover:opacity-70 active:opacity-50",
        "flex size-20 cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 border-default p-4",
        "data-[selected=true]:border-yellow-200"
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      {/* <span {...getWrapperProps()}>
        <span {...getControlProps()} />
      </span> */}
      <div className="text-center flex flex-col justify-center items-center gap-2">
        {children}
        {/* {children && <span {...getLabelProps()}>{children}</span>} */}
        {/* {description && <span className="text-small text-foreground opacity-70">{description}</span>} */}
      </div>
    </Component>
  );
}
