"use client";
import { useSearchParams } from "next/navigation";
import { usePathname } from "@/i18n/routing";
import { useEffect } from "react";
export default function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    // const url = `${pathname}?${searchParams}`;
    // console.log(url);
  }, [pathname, searchParams]);
  return null;
}
