"use client";
import clsx from "clsx";
import { Link } from "@/i18n/routing";
import { Button } from "@nextui-org/react";
import { ReactNode, useState } from "react";

import { GiPokerHand } from "react-icons/gi";
import { IoGiftSharp, IoPerson } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";

import { ProfileLinks } from "@/lib/constant";
import { useMenu } from "@/components/useMenu";

import { useTranslations } from "next-intl";
import { LogoutAction } from "@/lib/actions";
import { useFormStatus } from "react-dom";
export default function SideNav({ username, hasSession }: { username?: string; hasSession: boolean }) {
  const [accountExpend, setAccountExpend] = useState(false);
  const { isMenuOpen, toggleMenu } = useMenu();
  const t = useTranslations();

  const OnClick = () => {
    toggleMenu();
  };

  const LogoutHandler = async () => {
    await LogoutAction();
    toggleMenu();
  };

  return (
    <>
      <div
        onClick={OnClick}
        role="button"
        className={clsx(
          "fixed left-0 top-0 z-[999] mt-16 size-full bg-white bg-opacity-50 backdrop-blur-sm transition-all duration-400 ease-linear",
          isMenuOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
        )}
      />
      <div
        // initial={{ x: "100%" }}
        // animate={{ x: isMenuOpen ? "0%" : "100%" }}
        // transition={{ ease: "anticipate", duration: 0.75 }}
        className={clsx(
          "fixed right-0 top-0 z-[1000] mt-16 flex h-[calc(100svh-4rem)] w-full max-w-[280px] flex-col transition-all duration-500 ease-in-out",
          isMenuOpen ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
        )}
      >
        <div className="xbg-yellow-200 absolute left-0 top-0 z-[-1] size-full w-[200%] bg-white drop-shadow-lg"></div>
        <div className="flex min-h-28 w-full justify-end bg-gradient-to-b from-[#FFEC95] to-[#E3C45C]">
          <div
            className={clsx(
              "flex w-full items-center gap-4 p-4 px-8",
              hasSession ? "flex-row justify-center" : "flex-col justify-between"
            )}
          >
            {hasSession ? (
              <>
                {/* <Avatar src="https://placehold.co/40x40" /> */}
                <span className="text-lg font-bold text-black">
                  {t("hello")}, {username}
                </span>
              </>
            ) : (
              <>
                <Button
                  onClick={OnClick}
                  as={Link}
                  href="/login"
                  className="w-full bg-black font-bold text-white"
                  radius="sm"
                >
                  {t("login")}
                </Button>
                <Button
                  as={Link}
                  onClick={OnClick}
                  href="/register"
                  className="w-full border border-black bg-transparent font-bold text-black"
                  radius="sm"
                >
                  {t("register")}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="mr-4 mt-4 space-y-4 text-black">
          <div className="flex flex-col gap-y-4 pl-4 text-right font-black uppercase [&>a]:flex [&>a]:w-full [&>a]:items-center [&>a]:justify-start [&>a]:gap-4">
            {/* <Link href="#">home</Link> */}
            <Link onClick={OnClick} href="/promotion">
              <div className="flex size-[32px] items-center justify-center">
                <IoGiftSharp size={24} />
              </div>
              {t("promotion")}
            </Link>
            <Link onClick={OnClick} href="/game?category=slot&search=&subProductCode=All&page=1&count=20">
              <div className="flex size-[32px] items-center justify-center">
                <GiPokerHand size={32} />
              </div>
              {t("games")}
            </Link>
            {/* <Link onClick={() => setIsMenuOpen((prev) => !prev)} href="#">
        bet log
      </Link> */}
            {hasSession && (
              <>
                <div
                  className="flex w-full cursor-pointer items-center justify-start gap-4"
                  onClick={() => {
                    setAccountExpend((prev) => !prev);
                  }}
                >
                  <div className="flex size-[32px] items-center justify-center">
                    <IoPerson size={32} />
                  </div>
                  {t("account")}
                  <div className="ml-auto mr-0">
                    <IoIosArrowUp
                      className={`transform transition-transform duration-300 ${accountExpend ? "" : "rotate-180"}`}
                      size={24}
                    />
                  </div>
                </div>
                <div className="relative overflow-hidden">
                  <div
                    className={clsx("flex w-full flex-col gap-2 transition-all duration-700 ease-soft-spring", {
                      "translate-y-0 opacity-100": accountExpend,
                      "-translate-y-full opacity-0": !accountExpend,
                    })}
                  >
                    {Object.entries(ProfileLinks).map(([text, href]) => (
                      <Link
                        onClick={OnClick}
                        key={href}
                        href={href}
                        className="flex items-center justify-start gap-4 pl-4 font-semibold text-white"
                      >
                        {t(text)}
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {hasSession && (
          <form action={LogoutHandler} className="mb-0 mt-auto px-8 pb-12">
            <LogoutButton>{t("logout")}</LogoutButton>
          </form>
        )}
      </div>
    </>
  );
}

const LogoutButton = (props: { children: ReactNode }) => {
  const { pending } = useFormStatus();
  return (
    <Button type="submit" isDisabled={pending} className="w-full border border-black bg-white font-bold" radius="sm">
      {props.children}
    </Button>
  );
};
