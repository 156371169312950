"use client";

import { Button, useDisclosure } from "@nextui-org/react";

import LanguageIcon from "@mui/icons-material/Language";
import LanguageModal from "./language-modal";
export default function LanguageButton() {
  const languageDisclosure = useDisclosure();

  return (
    <>
      <LanguageModal disclosure={languageDisclosure} />
      <Button
        onClick={() => languageDisclosure.onOpen()}
        className="aspect-square h-auto w-12 rounded-md bg-transparent"
        isIconOnly
      >
        <LanguageIcon sx={{ width: "100%" }} />
      </Button>
    </>
  );
}
