"use client";
import { Button } from "@nextui-org/react";
import { Link } from "@/i18n/routing";
import { useTranslations } from "next-intl";
export default function AuthButtonGroup() {
  const t = useTranslations();
  return (
    <>
      <Button as={Link} href="/login" className="w-full max-w-[120px] bg-[#F5D66B] font-bold text-black">
        {t("login")}
      </Button>
      <Button as={Link} href="/register" className="w-full max-w-[120px] bg-[#D0AD22] font-bold text-black">
        {t("register")}
      </Button>
    </>
  );
}
