"use client";

import { useMenu } from "@/components/useMenu";
import clsx from "clsx";

export default function Body(props: { children: React.ReactNode; className: string })
{
  const { isMenuOpen } = useMenu();
  return <body className={clsx(props.className, isMenuOpen && "overflow-y-hidden")}>{props.children}</body>;
}
