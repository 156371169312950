export default function Arrow(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        d="M20.4388 5.55863C20.0833 5.20083 19.6025 5 19.1012 5C18.6 5 18.1192 5.20083 17.7637 5.55863L10.9526 12.3591L4.23632 5.55863C3.88085 5.20083 3.39999 5 2.89876 5C2.39753 5 1.91667 5.20083 1.5612 5.55863C1.38338 5.73721 1.24223 5.94968 1.14591 6.18378C1.04959 6.41788 1 6.66897 1 6.92257C1 7.17617 1.04959 7.42726 1.14591 7.66136C1.24223 7.89546 1.38338 8.10793 1.5612 8.28651L9.60552 16.4318C9.7819 16.6118 9.99173 16.7547 10.2229 16.8523C10.4541 16.9498 10.7021 17 10.9526 17C11.203 17 11.451 16.9498 11.6822 16.8523C11.9134 16.7547 12.1232 16.6118 12.2996 16.4318L20.4388 8.28651C20.6166 8.10793 20.7578 7.89546 20.8541 7.66136C20.9504 7.42726 21 7.17617 21 6.92257C21 6.66897 20.9504 6.41788 20.8541 6.18378C20.7578 5.94968 20.6166 5.73721 20.4388 5.55863Z"
        fill="black"
      />
    </svg>
  );
}
